.hero{
    background-image: url('../../assets/bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: none;
    height: 100vh;
    background-attachment:fixed;
  }
.heroText{
    position: absolute;
    top:40%;
    left: 10%;
    z-index: 50;
    color: white;

    p{
        margin:4rem 0;
        font-size: 2rem;
        width: 75%;
    }
}
.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    opacity: .7;
    z-index: 10;
}
hr{
    width: 15%;
    border-radius: 2px;
}

.typing{
    width: 15%;
    animation: typing 2s steps(22), blink .5s step-start infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    font-size: 2em;
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }