li{
    margin-top: 10px;
}
.sep{
    width: 5%;
}
.subscribe-fm{
    margin-top: 30px;
    .input{
        padding: 5px;
        border-radius: 2px 0px;
        border: 0;
    }
    .input:focus{
        outline:none;
    }
    .button{
        padding: 5px 18px;
        border: 0;
        color: white;
    }

}