$theme-colors: (
  "primary": #0E7CF8,
  "danger": #ff4136,
  "secondary": #101057
);
@import '~bootstrap/scss/bootstrap.scss';


html {
  scroll-behavior: smooth;
}
*{
  transition: all 1s;
  
}