.product-image{
    object-position: center;
    object-fit: contain;
    height: 160px;
}
.card-header-height{
    height: 170px;
}
.card-height{
   height: 450px;
}