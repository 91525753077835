
.navbar {
    --bs-navbar-toggler-icon-bg: url("../../assets/menu.svg");
  }
// .logo{
//     width:150px;
//     margin-top: 20px;
// }
.btn-contact{
  border-radius: 5px;
  background: #25AEE1;
  padding: 10px 20px;
  color: white;
  border: none;
  font-weight: bold;
}
.btn-contact:hover{
  background: #173d4b;
}
.link:hover{
  color:#25AEE1 !important;
}
.active{
  color:#25AEE1 !important;
}
