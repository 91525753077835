.click{
    cursor: pointer;
}
.gp{
    padding:0 8%;
}
.t-spacer{
    padding-top:5%;
}
.b-spacer{
    padding-bottom:5%;
}
.divider{
    height: 3px;
    background: white;
    border-radius: 5px;
}
.span{
    font-size: .7em;
}
h1{
    color: var(--secondary);
}
.w-30{
    width:30%;
}
.w-45{
    width:45%;
}
.border-bottom-1{
    border-bottom: 1px solid #101057;
}

@media only screen and (max-width: 576px){
    .gp{
        padding:0 2%;
    }
}
